/* eslint no-console:0 */
import "@webcomponents/custom-elements"
import "abortcontroller-polyfill/dist/polyfill-patch-fetch"
import { loadGAScript, handleGAOptedOutEvent } from "../utils/google_analytics"
import { deferLoadGTMScript } from "../utils/google_tag_manager"
import { disableButtonHandler } from "../utils/turbolinks"
import { loadControllers } from "../controllers"
import { initializeAffiliateRecording, handleAffiliateOptedOutEvent } from "../utils/affiliate_recording"
import { handleExperimentOptedOutEvent } from "../utils/experiments_opt_out"
import { navigator } from "@hotwired/turbo"

loadControllers()
loadGAScript()
handleGAOptedOutEvent()
deferLoadGTMScript()
disableButtonHandler(document)
initializeAffiliateRecording(window.location, document.referrer)
handleAffiliateOptedOutEvent()
handleExperimentOptedOutEvent()

document.addEventListener("turbo:click", (event) => {
  document.body.classList.add("loading")
  if (event.detail.url.endsWith("#content")) {
    const content = document.getElementById("content")
    if (content) {
      content.scrollIntoView()
    }
  }
})

document.addEventListener("turbo:before-visit", () => {
  document.body.classList.add("loading")
})

document.addEventListener("turbo:render", () => {
  document.body.classList.remove("loading")
})

document.addEventListener("turbo:submit-end", async (event) => {
  // Turbo doesn't handle 404 page requests very well.
  // The browser url is not updated, and the back button does not work.
  // This code prevents that from happening.
  if (!event.detail.success && event.detail.fetchResponse?.response?.url) {
    event.preventDefault()
    navigator.history.push(new URL(event.detail.fetchResponse.response.url))
  }
})

document.addEventListener("turbo:load", (event) => {
  if (typeof window.Cookiebot === "object" && "hasResponse" in window.Cookiebot && !window.Cookiebot.hasResponse) {
    window.Cookiebot.show()
  }
})
